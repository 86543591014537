//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useProductionFormStatusStore = defineStore('productionFormStatusStore', {
  state: () => ({
    productionFormStatus: [] as any[]
  }),
  actions: {
    async fetchProductionFormStatus() {
      if (this.productionFormStatus.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/productionFormRows/status')
          this.productionFormStatus = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
